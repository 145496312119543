/* 
general 
-------
*/
body {
    font-size: 16px;
    line-height: 1.6;
    color: #141414;
    overflow-x: hidden;
  }
  
  .container-fluid {
    margin: 0 auto;
    max-width: 1200px;
    padding: 0 15px;
  }
  
  .ant-layout {
    background: #fff !important;
  }
  
  .mainLayout .ant-layout-header {
    /* background: #471F3A; */
    background: #5516CA;
    padding: 0;
    height: auto;
    line-height: 1.6;
  }
  
  p {
    font-size: 16px;
    line-height: 1.6;
  }
  
  .bgGray {
    background: #fafafa;
  }

  .bgFooter {
    /* background: #471F3A; */
    background: #5E17EB;
    color: #F3E3ED; 
  }
  .bgService1 {
    /* background: #471F3A; */
    /* background: #5E17EB; */
    background: linear-gradient(217deg, #5516CA, rgba(255,0,0,0) 100.71%), linear-gradient(127deg, #e66465, rgba(0,255,0,0) 60.71%), linear-gradient(336deg, rgba(0,0,255,.8), rgba(0,0,255,0) 70.71%);
    color: white; 
  }

  .bgBlack {
    background: #1F1F1F;
  }
  .bgPrimaire {
    background: #5319CA;
  }
  
  /* 
  title holder
  ------------
  */
  .titleHolder {
    text-align: center;
    position: relative;
    padding: 0 0 20px;
    margin: 0 0 40px;
  }
  
  .titleHolder:after {
    transform: translateX(-50%);
    content: '';
    background: #1890ff;
    position: absolute;
    bottom: 0;
    left: 50%;
    height: 3px;
    width: 50px;
  }
  
  .titleHolder h2 {
    font-size: 28px;
    margin: 0;
  }
  
  /* 
  header
  ------ 
  */
  .ant-layout-header {
    position: fixed;
    left: 0;
    right: 0;
    z-index: 999;
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: 'white';
    padding: 15px 0;
  }
  
  .header .logo {
    font-size: 20px;
    text-transform: uppercase;
    font-weight: 400;
  }
  
  .header .logo .fas {
    color: #F9F1F6;
    margin: 0 5px 0 0;
    font-size: 28px;
  }
  
  .header .logo a {
    color: #F9F1F6;
  }
  
  .header .ant-anchor-wrapper {
    background: none;
  }
  
  .header .ant-anchor {
    display: flex;
  }
  
  .header .ant-anchor-ink {
    display: none;
  }
  
  .header .ant-anchor-link {
    padding: 0 20px;
    line-height: 1.4;
    position: relative;
  }
  
  .header .ant-anchor-link:before,
  .header .ant-anchor-link:after {
    content: '';
    background: white;
    width: 1px;
    position: absolute;
    top: 2px;
    bottom: 2px;
    left: 30%;
    opacity: 0;
    transition: all 0.3s linear;
  }
  
  .header .ant-anchor-link:after {
    left: auto;
    right: 30%;
  }
  
  .header .ant-anchor-link.ant-anchor-link-active:before,
  .header .ant-anchor-link:hover:before {
    left: 5%;
    opacity: 1;
  }
  
  .header .ant-anchor-link.ant-anchor-link-active:after,
  .header .ant-anchor-link:hover:after {
    right: 5%;
    opacity: 1;
  }
  
  /* ant menu */
  .header .ant-menu {
    border: 0;
    background: none !important;
  }
  
  .header .ant-menu li.ant-menu-item {
    position: relative;
    color: #F9F1F6;
  }
  
  .header .ant-menu li.ant-menu-item:before,
  .header .ant-menu li.ant-menu-item:after {
    content: '';
    background: #F9F1F6;
    width: 1px;
    position: absolute;
    top: 2px;
    bottom: 2px;
    left: 30%;
    opacity: 0;
    transition: all 0.3s linear;
  }
  
  .header .ant-menu li.ant-menu-item:after {
    left: auto;
    right: 30%;
  }
  
  .header .ant-menu li.ant-menu-item,
  .header .ant-menu li.ant-menu-item-selected {
    border: 0 !important;
  }
  
  .header .ant-menu li.ant-menu-item-selected,
  .header .ant-menu li.ant-menu-item:hover {
    color: #F9F1F6;
    background: none;
    color: #F9F1F6;
  }
  
  .header .ant-menu li.ant-menu-item-selected:before,
  .header .ant-menu li.ant-menu-item:hover:before {
    left: 5%;
    opacity: 1;
  }
  
  .header .ant-menu li.ant-menu-item-selected:after,
  .header .ant-menu li.ant-menu-item:hover:after {
    right: 5%;
    opacity: 1;
  }
  
  /* 
  hero
  ---- 
  */
  .heroBlock {
    background: radial-gradient(#3E1093 10% , #5516CA 60% );
    background-position: 50% 100%;
    background-size: cover;
    height: 100vh;
  }
  
  .heroBlock .container-fluid {
    display: block !important;
  }
  
  .heroBlock .ant-carousel,
  .heroBlock .ant-carousel div {
    height: 100%;
  }
  
  .heroBlock .slick-dots li button {
    background: #000 !important;
  }
  
  .heroBlock .slick-dots li.slick-active button {
    background: #1890ff !important;
  }
  
  .heroBlock .content {
    max-width: 640px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 0 70px;
  }
  
  .heroBlock h3 {
    font-size: 30px;
    line-height: 1.2;
    margin: 0 0 30px;
  }
  
  .heroBlock p {
    margin: 0 0 30px;
  }
  
  .heroBlock .btnHolder {
    height: auto !important;
  }
  
  .heroBlock .btnHolder button,
  .heroBlock .btnHolder .fas {
    margin: 0 10px 0 0;
  }
  
  /* 
  block
  ----- 
  */
  .block {
    padding: 60px 0;
    
  }
  
  /* 
  about
  ----- 
  */
  .featureBlock {
    background-color: white;
    background-position: 50% 0;
    background-size: cover;
    background-attachment: fixed;
    color: #141414;
    position: relative;
  } 
  .aboutBlock .contentHolder {
    text-align: center;
    margin: 0 0 50px;
  }
  
  .aboutBlock .icon {
    font-size: 40px;
    color: #1890ff;
    margin: 0 0 10px;
  }
  
  .aboutBlock .content {
    text-align: center;
  }
  
  /* 
  how it works
  ------------
  */
  .worksBlock {
    background-color: #551ACD;
    background-position: 50% 0;
    background-size: cover;
    background-attachment: fixed;
    color: #fff;
    position: relative;
  }
  
 
  
  .worksBlock h2 {
    color: #fff;
  }
  
  .worksBlock .contentHolder {
    text-align: center;
    color: #fff;
  }
  
  /* .worksBlock .contentHolder button {
    font-size: 40px;
    line-height: 1;
    background: none;
    border: 1px solid #fff;
    color: #fff;
    padding: 30px;
    border-radius: 50%;
    width: 110px;
    height: 110px;
  } */
  
  /* 
  faqs
  ----
  */
  .faqBlock .ant-collapse {
    margin: 0 0 40px;
  }
  
  .faqBlock .quickSupport {
    text-align: center;
    max-width: 740px;
    margin: auto;
  }
  
  .faqBlock .fas {
    margin: 0 10px 0 0;
  }
  
  /* 
  pricing block
  -------------
  */
  .pricingBlock .ant-list {
    text-align: center;
  }
  
  .pricingBlock .ant-row > div:nth-child(2) .ant-list-item {
    box-shadow: 0 0 12px 2px #f3f3f3;
    transform: scale(1.1);
    position: relative;
    z-index: 1;
  }
  
  .pricingBlock .ant-card-head-title {
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 400;
  }
  
  .pricingBlock .large {
    font-size: 38px;
    line-height: 1;
    margin: 0 0 20px;
  }
  
  .pricingBlock .fab {
    margin: 0 10px 0 0;
  }
  
  /* 
  contact
  -------
  */
  .contactBlock .ant-form {
    max-width: 640px;
    margin: auto;
  }
  
  .contactBlock .ant-form textarea {
    min-height: 120px;
    height: 120px;
  }
  
  /* 
  footer
  ------
  */
  .footer {
    padding: 0 15px;
    background-color: #1F1F1F;
    color: #fff;
    
  }

  .ant-layout-footer {
    background: #111 !important;
    color: #fff !important;
    padding: 60px 0 !important;
    text-align: center;
  }
  
  .footer .logo {
    font-size: 22px;
    text-transform: uppercase;
    margin: 0 0 20px;
  }
  
  .footer .fas {
    color: #1890ff;
    font-size: 28px;
    margin: 0 5px 0 0;
  }
  
  .footer .logo a {
    color: #fff;
  }
  
  .footer .socials {
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    font-size: 24px;
  }
  
  .footer .socials li {
    margin: 0 20px;
  }
  
  .footer .socials a {
    color: #fff;
  }
  
  .footer .socials a:hover {
    color: #1890ff;
  }
  
  .footer .goTop:hover {
    opacity: 0.7;
  }
  
  .mobileVisible {
    display: none;
  }
  
  .mobileHidden {
    display: block;
  }
  
  /* 
  responsive
  ----------
  */
  @media only screen and (max-width: 991px) {
    .pricingBlock .ant-row > div:nth-child(2) .ant-list-item {
      transform: scale(1.14);
    }
  }
  
  @media only screen and (max-width: 767px) {
    .pricingBlock .ant-row > div:nth-child(2) .ant-list-item {
      transform: scale(1);
    }
  
    .mobileVisible {
      display: block;
    }
    
    .mobileHidden {
      display: none;
    }
  }