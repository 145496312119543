/* CustomEmailEditor.css */
/* Changer la couleur de fond de l'éditeur */
.unlayer-editor .editor-panel {
    background-color: #f7f7f7 !important;
}

/* Changer la couleur de fond de la barre d'outils */
.unlayer-editor .editor-toolbar {
    background-color: #3f51b5 !important;
}

/* Changer la couleur du texte des boutons dans la barre d'outils */
.unlayer-editor .editor-toolbar .editor-tools .editor-tool-item {
    color: #fff !important;
}

/* Changer la couleur de fond des boutons */
.unlayer-editor .editor-toolbar .editor-tools .editor-tool-item button {
    background-color: #673ab7 !important;
}

/* Changer la couleur de l'onglet des blocs */
.unlayer-editor .editor-panel .block-title {
    color: #3f51b5 !important;
}