.rbc-calendar {
    background-color: #ffffff; /* Fond blanc pur */
    font-family: 'Poppins', sans-serif; /* Police élégante et moderne */
    font-size: 0.9rem;
    border-radius: 12px;
    overflow: hidden; /* Supprime les débordements */
}

.rbc-header {
    background-color: #5E17EB; /* Bleu moderne */
    color: #ffffff; /* Texte blanc */
    text-align: center;
    font-weight: 600;
    padding: 10px 0;
    text-transform: uppercase;
    letter-spacing: 1px; /* Espacement des lettres */
    border-bottom: 2px solid #5E17EB; /* Ligne pour le contraste */
}

.rbc-day-bg {
    background-color: #f7f9fc; /* Gris clair élégant */
    transition: background-color 0.3s; /* Transition douce au survol */
}

.rbc-day-bg:hover {
    background-color: #eaf3ff; /* Survol des jours */
}

.rbc-today {
    background-color: #e3f2fd; /* Bleu clair pour le jour actuel */
    border: 2px solid #5E17EB; /* Accentuation par une bordure */
    box-shadow: inset 0 0 10px rgba(33, 150, 243, 0.2); /* Effet de lumière */
}


.rbc-event {
    background-color: #5e17eb; /* Vert moderne pour les événements */
    color: #ffffff; /* Texte blanc */
    border-radius: 8px; /* Coins arrondis pour un look moderne */
    padding: 6px;
    font-size: 0.85rem;
    font-weight: 500;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Ombre légère */
    transition: transform 0.2s, box-shadow 0.2s; /* Animation au survol */
}

.rbc-event:hover {
    transform: scale(1.05); /* Agrandissement au survol */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Ombre renforcée */
}

.rbc-toolbar {
    background-color: #ffffff; /* Fond blanc pour contraste */
    color: #333; /* Texte sombre */
    
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.rbc-toolbar button {
    background-color: #4a90e2;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 5px 10px;
    font-size: 0.85rem;
    cursor: pointer;
    transition: background-color 0.3s;
}

.rbc-toolbar button:hover {
    background-color: #357abd;
}

.rbc-time-slot {
    border-top: 1px dashed #ddd; /* Ligne pointillée pour les plages horaires */
}

.rbc-time-view .rbc-time-header-cell {
    background-color: #f4f6f9;
    color: #555;
    font-weight: bold;
}

.rbc-time-view .rbc-time-content {
    border-left: 2px solid #e0e0e0; /* Ligne principale */
}
