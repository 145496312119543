/* editorStyles.css */
.ql-editor {
    font-family: 'Roboto', sans-serif;
  }
  
  /* Boutons */

  .ql-toolbar button {
    background-color: #f5f5f5;
    color: #333;
    border: none;
    border-radius: 4px;
    padding: 8px;
    margin: 2px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .ql-toolbar button:hover {
    background-color: #ddd;
  }

  /* Bouton sélectionné */
.ql-toolbar button.ql-active {
    background-color: #ddd; /* Nouvelle couleur pour les boutons sélectionnés */
    
  }
  
  /* Conteneur de l'éditeur */
  .ql-container {
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 10px;
    min-height: 200px;
    
    /*box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);*/
  }
/* Style pour les listes à puces */
.ql-editor ul,
.ql-editor ol {
  list-style-type: none;
  padding-left: 1em;
  font-size: 16px;
}
